import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'


export default class ContactForm extends React.Component {
    // Since `addToMailchimp` returns a promise, you
    // can handle the response in two different ways:
  
    // Note that you need to send an email & optionally, listFields
    // these values can be pulled from React state, form fields,
    // or wherever.  (Personally, I recommend storing in state).
  
    state = {
        email: null,
    }

    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        console.log('submit', this.state);

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);

                if (result !== 'success') {
                    throw msg;
                }
                alert(msg);
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }
  
    render () {
      return (
        <form onSubmit={this._handleSubmit} className="form">
            <span>Newsletter</span>
            <div className="field">
                <div className="control">
                    <input
                        type="email"
                        placeholder="Unesite svoj Email"
                        onChange={this._handleChange}
                        name="email"
                        className="input"
                    />
                </div>
            </div>
          <input type="submit" value="Prijavi se" className="button" />
        </form>
      )
    }
  }
  
